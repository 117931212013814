<template>
  <div>
    <div v-if="car.categories.length">
      <h4>Current category</h4>
      <div class="list-group mb-2">
        <a :href="`/browse/${cat.id}`" target="_blank" class="list-group-item list-group-item-action" v-for="cat in car.categories">{{ cat.name }}</a>
      </div>
    </div>

    <div v-if="options.length" class="mb-1">
      <treeselect 
        @select="fetchSelectedTree" 
        v-model="selectedCategory" 
        :multiple="false" 
        :disable-branch-nodes="true"
        :options="options" />
    </div>
    
    <div v-if="changed" class="bg-light rounded p-3 mt-3">
      <div v-if="selectedTree.length">
        <ul>
          <li v-for="cat in selectedTree">{{ cat.name }}</li>
        </ul>
      </div>
      
      <button @click="moveCar" :disabled="loading" class="btn btn-primary btn-block mt-2">Move</button>
    </div>

    <div v-if="car.category_suggestions.length" style="visibility: hidden;" class="mt-4">
      <h4>Suggestions</h4>
      <table class="table table-sm">
        <tbody>
          <tr v-for="suggestion in car.category_suggestions">
            <td>
              {{ suggestion.full_name }}
            </td>
            <td>
              {{ suggestion.score }}%
            </td>
            <td>
              <button :disabled="loading" @click="acceptSuggestion(suggestion)" class="btn btn-sm btn-primary">Accept</button>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>


  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// import CategorySelector from './CategorySelector.vue'
import axios from 'axios'
export default {

  name: 'carCategorize',
  components: {Treeselect},
  props: ['car'],
  data () {
    return {
      selectedCategory: null,
      options: [],
      loading: false,
      selectedTree: []
    }
  },
  computed: {
    changed() {
      return this.selectedCategory !== this.car.non_year_category_id
    }    
  },

  methods: {
    acceptSuggestion(suggestion){
      this.selectedCategory = suggestion.category_id
      this.moveCar()
    },
    moveCar(){
      this.loading = true
      axios.get(`/cars/${this.car.id}/link_to_category.json`, {
        params: {
          category_id: this.selectedCategory,
        },
      }).then((response) => {
        // TODO
        this.loading = false
        this.$emit('update', response.data)
        console.log(response.data)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    fetchSelectedTree(node, instanceId) {
      console.log(node)
      axios.get(`/categories/${node.id}/parents`)
      .then((response) => {
        this.selectedTree = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    fetchOptions() {
      axios.get(`/categories/${this.car.categories[0].id}/category_tree.json`)
      .then((response) => {
        this.options = response.data
        this.fetchSelectedTree({id: this.selectedCategory})
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  mounted(){
    this.selectedCategory = this.car.non_year_category_id
    this.fetchOptions()
  }
}
</script>

<style lang="css" scoped>
</style>